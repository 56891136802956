.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

.inner_title_menu li:hover {
  cursor: pointer;
}

.hp_step3_list li:hover {
  cursor: pointer;
}

table.project_table td.td_width2 {
  width: unset;
}

.hp_step_setting_wrap_content {
  overflow: hidden;
}

.ReactModal__Overlay--after-open {
  z-index: 10;
}

.shopComm .board_paging a {
  margin: 0;
}

.board_paging a:hover {
  cursor: pointer;
}

.sub_wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.sub_page {
  flex: 1;
  margin-top: 121.23px;
}