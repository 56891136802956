.board_text_wrapper {
  display: flex;
  align-items: center;
}
.board_textarea {
  padding: 15px 20px;
}
.board_mobile_tr {
  width: 100%;
  cursor: pointer;
  font-size: 15px;
  text-align: left;
}
.board_mobile_td_blue {
  padding-right: 10px !important;
  color: #6b90dc !important;
}
.board_mobile_td_gray {
  padding-right: 10px !important;
  color: #c8c8c8 !important;
}