.port_wrapper{
  display: flex;
  gap: 70px 20px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.port_img_wrapper {
  width: calc((100% - 60px) / 4);
}

@media screen and (max-width: 767px){
  .port_img_wrapper {
    width: calc(50% - 10px);
  } 
}

.port_link {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.port_img_aspect {
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
}

.port_img {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain !important;
  object-position: center !important;
}

.port_title {
  margin-top: 10px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.port_detail_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
}

.port_content {
  width: 70%;
  word-wrap: break-word;
}

@media screen and (max-width: 767px) {
  .port_content {
    width: 100%;
  }
}

.port_content > figure{
  margin: 12px 0;
}

.port_content > p > img {
  width: 100%;
}

.port_content .image > img {
  width: 100%;
}

.port_container {
  width: 100%;
  padding: 50px 20px;
  position: relative;
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
}

.port_btn_wrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.port_btn{
  min-width: unset;
}

.port_view_wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.port_view_wrapper > p {
  width: 100%;
}