.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.spinner {
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: #1d3557;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
